import React, { useState, useEffect } from "react"
import Helmet from 'react-helmet'
import FighterList from '../components/FighterList'

const FightersPage = () => {

  return (
    <>
      <Helmet>
        <title>Fanpowered Fighters - Vote for the Fights You Want to See.</title>
      <meta name="description" content="We're on a mission to put the power back in the hands of the fans. Vote on the boxing fights you want to see." />
      </Helmet>
      <FighterList />
    </>
  )
}

export default FightersPage